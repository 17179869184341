/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
  background-color: #fff;
}

.datepicker-dropdown:before {
  border-bottom-color: #adb5bd;
}

.datepicker-dropdown:after {
  border-bottom-color: #adb5bd;
}

.datepicker-dropdown.datepicker-orient-top:before {
  border-top-color: #adb5bd;
}

.datepicker-dropdown.datepicker-orient-top:after {
  border-top-color: #adb5bd;
}

.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
  background-color: #495057;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #6c757d;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  color: #6c757d;
}

.datepicker table tr td.highlighted {
  background-color: #495057;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #ffc107;
  background-image: none;
  background-repeat: no-repeat;
  border-color: #ffc107;
  color: #212529;
}

.datepicker table tr td.today:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today.disabled[disabled] {
  background-color: #ffe7a0;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled:hover[disabled],
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled:hover.active {
  background-color: #ffe7a0;
}

.datepicker table tr td.today:hover:hover {
  color: #222222;
}

.datepicker table tr td.today.active:hover {
  color: #212529;
}

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
  background-color: #495057;
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
  background-color: #a07800;
  background-image: none;
  background-repeat: no-repeat;
  border-color: #a07800;
}

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today.disabled[disabled] {
  background-color: #a07800;
}

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled],
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled:hover.active {
  background-color: #a07800;
}

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background-color: #495057;
  background-image: none;
  background-repeat: no-repeat;
  border-color: #495057;
  color: #fff;
  text-shadow: none;
}

.datepicker table tr td.selected:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected.disabled[disabled] {
  background-color: #495057;
}

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
  background-color: #495057;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #a881da;
  background-image: none;
  background-repeat: no-repeat;
  border-color: #a881da;
  color: #fff;
  text-shadow: none;
}

.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled.active {
  background-color: #a881da;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled:hover.active {
  background-color: #8c5acf;
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background-color: #8c5acf;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  color: #fff;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-color: #ded0f1;
  background-image: none;
  background-repeat: no-repeat;
  border-color: #ded0f1;
  color: #fff;
  text-shadow: none;
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #a881da;
}

.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled.active {
  background-color: #7137be;
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #6c757d;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background-color: #fff;
}

.input-daterange .add-on {
  color: #495057;
  text-shadow: 0 1px 0 #1a1d20;
  background-color: #495057;
  border-color: #ced4da;
}

@media (prefers-color-scheme: dark) {
  /*!
   * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
   *
   * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
   */
  .datepicker {
    background-color: #3b3b3b;
  }

  .datepicker-dropdown:before {
    border-bottom-color: #7e7e7e;
  }

  .datepicker-dropdown:after {
    border-bottom-color: #7e7e7e;
  }

  .datepicker-dropdown.datepicker-orient-top:before {
    border-top-color: #7e7e7e;
  }

  .datepicker-dropdown.datepicker-orient-top:after {
    border-top-color: #7e7e7e;
  }

  .datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
    background-color: #b1b1b1;
  }

  .datepicker table tr td.old,
.datepicker table tr td.new {
    color: #5a5a5a;
  }

  .datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
    color: #5a5a5a;
  }

  .datepicker table tr td.highlighted {
    background-color: #b1b1b1;
  }

  .datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
    background-color: #f39c12;
    background-image: none;
    background-repeat: no-repeat;
    border-color: #f39c12;
    color: #e1e1e1;
  }

  .datepicker table tr td.today:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today.disabled[disabled] {
    background-color: #fad9a4;
  }

  .datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled:hover[disabled],
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled:hover.active {
    background-color: #fad9a4;
  }

  .datepicker table tr td.today:hover:hover {
    color: #222222;
  }

  .datepicker table tr td.today.active:hover {
    color: #e1e1e1;
  }

  .datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
    background-color: #b1b1b1;
  }

  .datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
    background-color: #f8c573;
    background-image: none;
    background-repeat: no-repeat;
    border-color: #f8c573;
  }

  .datepicker table tr td.range.today:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today.disabled[disabled] {
    background-color: #f8c573;
  }

  .datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled],
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled:hover.active {
    background-color: #f8c573;
  }

  .datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
    background-color: #b1b1b1;
    background-image: none;
    background-repeat: no-repeat;
    border-color: #b1b1b1;
    color: #222222;
    text-shadow: none;
  }

  .datepicker table tr td.selected:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected.disabled[disabled] {
    background-color: #b1b1b1;
  }

  .datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
    background-color: #b1b1b1;
  }

  .datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
    background-color: #a881da;
    background-image: none;
    background-repeat: no-repeat;
    border-color: #a881da;
    color: #fafafa;
    text-shadow: none;
  }

  .datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.disabled.active {
    background-color: #a881da;
  }

  .datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled:hover[disabled],
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled:hover.active {
    background-color: #8c5acf;
  }

  .datepicker table tr td span:hover,
.datepicker table tr td span.focused {
    background-color: #8c5acf;
  }

  .datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
    color: #fafafa;
  }

  .datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
    background-color: #a881da;
    background-image: none;
    background-repeat: no-repeat;
    border-color: #a881da;
    color: #fafafa;
    text-shadow: none;
  }

  .datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
    background-color: #a881da;
  }

  .datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled.active {
    background-color: #7137be;
  }

  .datepicker table tr td span.old,
.datepicker table tr td span.new {
    color: #5a5a5a;
  }

  .datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
    background-color: #3b3b3b;
  }

  .input-daterange .add-on {
    color: #b1b1b1;
    text-shadow: 0 1px 0 #7e7e7e;
    background-color: #b1b1b1;
    border-color: #515151;
  }
}

/*# sourceMappingURL=bootstrap-datepicker-dark.css.map */